@import 'variables';

body {
  // Vertical
  &.vertical {
    min-height: 100vh;
    div#main {
      overflow-x: hidden;
    }
  }
}

// Larger than phone
@media only screen and (min-width : $small-breakpoint-upper-num) {
  body {
    // Vertical
    &.vertical {
      div#main {
        padding-top: $peek-3-vertical;
      }
    }
    
  // Horizontal variant 
    &.horizontal {
      overflow-x: scroll; 
      overflow-y:hidden;
      
      div#main {
        overflow-x: auto;
      }
    }
  }
} 