@import "variables"; 

.suite-display {
  position: relative;
  
  max-height: 92vh;
  // height: 40vw;

  &.forty-eight-width {
    width: 80vw;
    aspect-ratio: 2;
  }

  &.forty-width {
    height: $peek-14p5-vertical;
    aspect-ratio: 1.6666666667;
  }

  a.positioned-suite-image-link, .positioned-suite-image {
    display: block;
    position: absolute;

    img {
      position: static;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.positioned-suite-image-link {
  display: block;
  position: absolute;
}

.positioned-suite-image {
  display: block;
  position: absolute;
}

/*** For debugging ***/
body.debug {
  .suite-display {
    background-color: #e6e6e3;
  }
}