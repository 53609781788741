@import 'variables';

// body {
//   transition: background-color 0.3s ease, opacity 0.3s ease;
// }

body.dark-mode {
  background-color: $black;
  color: $newspaper;

  div, p, a, h1, h2, h3 {
    color: $newspaper;
  }

  .nav-outer-wrapper {
    &:hover {
      background-color: $black;
    }
  }
  .small-nav {
    background-color: $black;

    .submenu.open {
      background-color: $black;
    }
  }
}