@import 'variables';


.nav-outer-wrapper {
  // transition: background-color 0.3s ease, opacity 0.3s ease;
  padding: 2.5rem 2.38095238095vw;

  nav {
    div.link-wrapper {
      position: relative;

      a {
        &.active {
          &::before {
            content: '•';
            position: absolute;
            left: -0.875rem; 
          }
        }
        
      }
      &:hover {
        a {
          &::before {
            content: '•';
            position: absolute;
            left: -0.875rem; 
          }
        }
      }
    }
    @media only screen and (min-width : $large-breakpoint-min-width) {
      .page-title {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
    }
    
  }

  &:hover {
    background-color: $newspaper;
    opacity: 0.92;

    nav {
      .page-title {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
  
.small-nav {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: $newspaper;
  opacity: 0.92;

  .submenu {
    display: none;

    &.open {
      display: block;
      background-color: $newspaper;
      opacity: 0.92;
    }
  }
}


@media only screen and (min-width : $small-breakpoint-upper-num) {
  .small-nav {
    display: none;
  }
}

@media only screen and (max-width : $small-breakpoint-lower-num) {
  .large-nav {
    display: none;
  }
  .small-nav {
    display: block;
    .lightbox-hide {
      transition: display 0.3s ease;
    }
    .lightbox-show {
      display: none;
    }
    &.lightbox-open {
      .lightbox-hide {
        display: none;
      }
      .lightbox-show {
        display: block;
      }
    }
  }
}
