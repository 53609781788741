$newspaper: #f2f2ee;
$black: #0a0a0a;

$large-breakpoint-min-width: 1024px;

$small-breakpoint-lower-num: 812px;
$small-breakpoint-upper-num: 813px;

// THE PEEK
// Previous code:
//
// if ($(window).width() > 812) {
//   window.PEEK = $(window).width() / 21;
// } else {
//   window.PEEK = $(window).height() / 21;
// }
//
// window.hPEEK = PEEK;
// window.vPEEK = PEEK / 2;

// RULES
// Large screens:
// PEEK = 1/21 of width

// Small screens:
// PEEK = 1/21 of height

// Horizontal peek = PEEK
// Vertical peek = PEEK / 2

// CSS variables
// --peek: 1/21 of width
// --hPeek: var(--peek)
// --vPeek: var(--peek) / 2

// UNITS
// n / 21
// 0.5 = 2.380952380952381
// 1 = 4.761904761904762
// 2 = 9.523809523809524
// 3 = 14.285714285714286

$peek-half-vertical: 2.380952380952381vh;
$peek-vertical: 4.761904761904762vh;
$peek-2-vertical: 9.523809523809524vh;
$peek-2p5-vertical: 11.904761904761905vh; 
$peek-3-vertical: 14.285714285714286vh;
$peek-4p5-vertical: 21.42857142857143vh;
$peek-5-vertical: 23.80952380952381vh; 
$peek-6-vertical: 28.571428571428573vh; 
$peek-6p5-vertical: 30.952380952380953vh;
$peek-14p5-vertical: 69.04761904761905vh; 

$peek-half-horizontal: 2.380952380952381vw;
$peek-horizontal: 4.761904761904762vw;
$peek-2-horizontal: 9.523809523809524vw;
$peek-3-horizontal: 14.285714285714286vw;