@import 'variables';

.home-outer-wrapper {
  margin-bottom: 9.523809523vh;
}

.rail {
  height: 80.95238095238095vh;
  display: flex;
  flex-direction: row;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  .center-panel, .left-panel, .right-panel {
    padding-bottom: $peek-2p5-vertical;
  }

  .left-panel, .right-panel {
    width: 4.761904761904762vw;
    flex-grow: 0;
    overflow-x: hidden;
    position: relative;
    padding-right: 0;
    padding-left: 0;

    img {
      height: 100%;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      max-width: none;
    }
  }
  .left-panel {
    direction: rtl;
    img {
      right: 0;
    }
  }
  .right-panel {
    direction: ltr;
    img {
      left: 0;
    }
  }

  .center-panel {
    flex-grow: 1;
    position: relative;
    padding-left: 4.761904761904762vw;
    padding-right: 4.761904761904762vw;

    .center-panel-inner-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      
      img {
        object-fit: contain;
      }

      .suite-wrapper {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .caption {
      position: absolute;
      bottom: -3.5714285714vh;
      left: 0;
      right: 0;
      text-align: center;
    }

  }
}



// /******************************************/
// /*                                        */
// /*        MEDIUM & LARGE SCREENS          */
// /*                                        */
// /******************************************/

// @media only screen and (min-width : 813px) { 
//   .home-outer-wrapper {
//     padding: 50vh 0;
//   }
// }

// /******************************************/
// /*                                        */
// /*          PORTRAIT STYLES               */
// /*                                        */
// /******************************************/

// body.portrait {
//   .left-panel, .right-panel {
//     display: none;
//   }
//   .caption {
//     padding-bottom: 2rem;
//   }
// }

// // Is this right?
// @media only screen 
//   and (min-device-width: 668px) 
//   and (max-device-width: 812px)  {
//   body.portrait {
//     .caption {
//       padding-bottom: 1rem;
//     }
//   }
// }

// @media only screen 
//   and (min-device-width: 375px) 
//   and (max-device-width: 667px) 
//   and (orientation: portrait) { 
//   body.portrait {
//     .caption {
//       padding-bottom: 0.5rem;
//     }
//   }
// }


// /******************************************/
// /*                                        */
// /*          LANDSCAPE STYLES              */
// /*                                        */
// /******************************************/

// body.landscape {

// }

// /******************************************/
// /*                                        */
// /*                MOBILE                  */
// /*                                        */
// /******************************************/

// @media only screen and (max-width : 812px) {

//   .home-outer-wrapper {
//     padding: 48vh 0 33vh 0;
//   }

//   // Landscape styles
//   body.landscape {
//     .center-panel {
//       .center-panel-inner-wrapper {
//         img {
//           height: 100%;
//           max-height: 100%;
//         }
//       }
//     }
//   }
  
//   // Landscape styles
//   body.portrait {
//     .center-panel {
//       padding: 2rem 1rem; // This sizes the inner wrapper, and therefore suites
//       margin-bottom: 4rem;
      
//       .center-panel-inner-wrapper {
//         img {
//           position: static;
//         }
//       }

//       .caption {
//         bottom: -0.5rem;
//       }
//     }
//   }
  
//   // shared styles
//   body {
//     .center-panel {
//       padding: 2rem; // This sizes the inner wrapper, and therefore suites
//       margin-bottom: 2rem;

//     }
    
//     .left-panel, .right-panel {
//       display: none;
//     }
  
//   }
// }


// /******************************************/
// /*                                        */
// /*            SHARED STYLES               */
// /*                                        */
// /******************************************/

// .caption {
//   text-align: center;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   //padding-bottom: 0.125rem;
//   padding-bottom: 1rem;
  
//   .dash-version {
//     display: none;
//   }
//   .colon-version {
//     display: block;
//   }
// }

// .rail {
//   // height: 650.25px;
//   // height: 90.47619047619048vh;
//   height: 80.95238095238095vh;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;

//   .photo-wrapper {
//   }
// }

// .rail-wrapper {
//   display: flex;
//   flex-direction: column;
// }

// .left-panel, .right-panel, .center-panel {
//   height: 100%;
//   position: relative; // for positioning images
//   img {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//   }
// }

// .left-panel, .right-panel {
//   // width: 30px;
//   width: 4.761904761904762vw;
//   flex-grow: 0;
//   img {
//     margin-top: auto;
//     margin-bottom: auto;
//     max-width: none;
//   }
//   img:hover {
//     opacity: 0.9;
//   }
// }

// .center-panel {
//   padding: 4rem; // This sizes the inner wrapper, and therefore suites

//   .center-panel-inner-wrapper {
//     display: block;
//     position: relative;
//     width: 100%;
//     height: 100%;
//     //background-color: pink;
//     img {
//       object-fit: contain;
//     }

//     .suite-wrapper {
//       max-width: 100%;
//       max-height: 100%;
//     }
//   }
  
//   .hidden-panel {
//     background-color: lightgoldenrodyellow;
    
//     position: absolute;
//     top: -1000000px;
//     left: -1000000px;
//     width: 100%;
//     height: 100%;
  
//     .suite-wrapper {
//       display: block;
//     }
//   }
// }
// .center-panel.text-only {
//   .center-panel-inner-wrapper {
//     display: none;
//   }
// }

// // Added for sized peeking images for suites

// .left-panel, .right-panel {
//   padding: 60px 0; // TODO

//   .peeking-image-wrapper {
//     position: absolute;
//     height: 100%;
//     width: 100%;

//     img {
//       margin: 0;
//     }
//   }
// }


// // End added for sized peekers

// .left-panel {
//   img {
//     right: 0; // change to PEEK
//   }
// }

// .right-panel {
//   img {
//     left: 0; // change to PEEK
//   }
// }

// .center-panel {
//   flex-grow: 1;

//   img {
//     margin: auto;
//     right: 0;
//     left: 0;
//   }
//   img:hover {
//     opacity: 0.9;
//   }
  
//   // By default, don't show suites
//   .suite-wrapper {
//     display: none;
//   }
// }

// .rail-wrapper.clicked {
//   .center-panel {
//     img:hover {
//       opacity: 1;
//     }
//   }
// }

// .center-panel.text-only {
//   position: static;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .caption {
//     position: static;
//     text-align: left;
//     max-width: 64rem;
    
//     @media only screen and (min-width : 813px) and (max-width : 1200px) {
//       padding: 0 11rem;
//     } 
//     @media only screen and (min-width : 1201px) {
//       padding: 0 6rem;
//     } 
//   }
  
//   // Don't show suites
//   .suite-wrapper {
//     display: none;
//   }
// }

// .center-panel.suite {
//   .suite-wrapper {
//     //display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     margin: auto;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;

//     .suite-display {
//       position: relative;

//       a.suite-image {
//         display: block;
//         position: absolute;

//         img {
//           position: static;
//         }
//       }
//     }
//   }
// }

// /***************
//  *  DEBUGGING  *
//  ***************/

// body.debug {
//   .rail.even {
//     // background-color: $darkened-dragan;
    
//     .left-panel, .right-panel {
//       // background-color: $dragan-brown;
//     }
//   }
  
//   .rail.odd {
//     .left-panel, .right-panel {
//       // background-color: $darkened-dragan;
//     }
//   }
  
//   .center-panel.suite {
//     .suite-wrapper {
//       .suite-display {
//         background-color: #e6e6e3;
//       }
//     }
//   }
// }
