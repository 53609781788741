.element-inner-wrapper {
  &.image {
    background-color: #e6e6e0;
  }
}
img.srcNotLoaded, img.srcError {
  opacity: 0;
  
  -webkit-transition: opacity 0s;
  -moz-transition: opacity 0s;
  -ms-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
}

img.srcLoaded {
  opacity: 1;

  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}