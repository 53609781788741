@import 'variables';

body.info {
}

div.info-outer {
  display: flex;
  flex-direction: column;
  // justify-content: end;
  justify-content: start;
}

.info-main, .info-row {
  h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .info-box {
    padding: 0;
    margin: 0 0 5rem 0;

    p {
      line-height: 1.7;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1535px) {
  .info-outer {
    .info-row {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .info-outer {
    .info-row {
      display: grid;
      grid-template-columns: 2fr 3fr 9fr;
    }
  }
}


/* Bigger than phone */
// @media only screen and (min-width : 813px) {
@media only screen and (min-width : 1024px) {
  .info-outer {
    height: 85.7143vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    
    .info-row {
      display: grid;
      // grid-template-columns: 2fr 3fr 2fr,
    }

    &.small-version {
      display: none;
    }
  }
  .info-main, .info-row {
    .info-box {
      white-space: normal;
      display: inline-block;
      float: none;
      text-align: left;
      vertical-align: top;
      // padding: 0 2.38095238095vw 4.7619047619vw;
    }
  } 
  .info-main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // text-align: center;
    white-space: nowrap;
    
    .xlarge-3.info-box.can-shrink {
      width: auto;
    }
  }
}


/* Mobile */
// @media only screen and (max-width : 812px) {
@media only screen and (max-width : 1023px) {
  div.info-outer{
    padding: 12.5vh 0;

    .info-box {
      // padding: 0 $peek-horizontal $peek-vertical;
    }
    &.large-version {
      display: none;
    }
  }
}

a {
  color: inherit !important;
}

p.small-break {
  margin-bottom: 0.25rem;
}

p.medium-break {
  margin-bottom: 0.5rem;
}

p.long-list {
  // padding-right: 1rem;
  line-height: 1.7;
}

/* NAV */

body.info {
  nav {
    bottom: 7rem;
    left: 0;
    right: 0;
  
    a {
      margin-right: 1rem;
    }
    a:last-child {
      margin-right: 0;
    }
    // a.current {
    //   color: $darkened-dragan;
    // }
  }
}
