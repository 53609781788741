body.lightbox-open {
  overflow: hidden;
  .thumb {
    visibility: hidden;
  }
}

.lightbox {
  display: none;
  width:100%;
  height:100%;
  position: fixed;
  z-index: 20;
  top:0px;
  right: auto;
  bottom: 0;
  left: auto;
  text-align: center;
  padding: 0;
  margin: 0;
  // background-color: $newspaper;

  .lightbox-inner-wrapper {
    position: relative;
    background-color: transparent;
    
    // Lightbox elements
    .lightbox-element {
      display: none;
      margin: 0 0 0 0;
    }
    .lightbox-element.live {
      display: block;
    }
    .image-caption {
      position: absolute;
      //bottom: 5rem;
      bottom: -3.5rem;
      width: 22rem;
      left: 50%;
      margin-left: -11rem;
    }
    
    .lightbox-element.text-only {
      text-align: center;

      .text-block {
        max-width: 32rem;
        margin: 0 auto;
      }
    }
  }
}
.lightbox.shown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.x-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  
  img.lightbox-x {
    width: 1.25rem;
    margin: 1rem;
    opacity: 1;
  }
  img.lightbox-x:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}