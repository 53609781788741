

@font-face {
  font-family: 'CourierSans';
  src: url('../fonts/CourierSans.eot'); /* IE9 Compat Modes */
  src: url('../fonts/CourierSans.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/CourierSans.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/CourierSans.woff') format('woff'), /* Modern Browsers */
       url('../fonts/CourierSans.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/CourierSans.svg#CourierSans') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

body, html {
  font-size: 12px;
}

body,html,div,p,a,h1,h2,h3 {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.5px;
  color: #353535;
  font-family: "CourierSans";
  letter-spacing: 0.5px
}

body i, html i, div i, p i, a i, h1 i, h2 i, h3 i {
  font-family: "CourierSans";
  font-style: normal;
  font-weight: normal
}