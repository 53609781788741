.short-outro {
  padding: 1.5rem 3rem;
  width: 100%;
  text-align: center;

  h1 {
    font-weight: normal;
    margin: 0 auto 0.5rem auto;
    
    img {
      width: 8rem;
    }
  }
}

.small-outro {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem auto 7rem auto;
}

nav {
  a {
    margin-right: 1rem;
  }
  a:last-child {
    margin-right: 0;
  }
  // a.current {
  //   color: $darkened-dragan;
  // }
}

.grid-wrapper {
  margin: 0 -2rem;
  padding-left: 2.38095238vw;
  padding-right: 2.38095238vw;
  margin-bottom: 9.523809523vh;
  
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  .grid-inner-wrapper {
    margin: 0 -2rem;
  }

  &::after {
    content: "";
    flex: auto;
  }

  .thumb-and-project-title {
    display: inline-block;
    .title {
      display: inline-block;
      width: 140px; // TODO
      text-align: right;
      vertical-align: text-bottom;
    }
  }

  .thumb {
    display: inline-block;
    margin: 2rem 1.5rem;
    
    img {
      height: 10rem;
      vertical-align: bottom;

      &:hover {
        cursor: pointer;
        opacity: 0.666;
      }
    }
    
    // img.srcNotLoaded, img.srcError {
    //   opacity: 0;
      
    //   -webkit-transition: opacity 0s;
    //   -moz-transition: opacity 0s;
    //   -ms-transition: opacity 0s;
    //   -o-transition: opacity 0s;
    //   transition: opacity 0s;
    // }
  
  }
  .thumb.suite-thumb {
    display: inline-block;
    vertical-align: bottom;
    width: 20rem;
    height: 10rem;
    
    .suite-thumb-inner-wrapper {
      position: relative;
    }
    .suite-thumb-inner-wrapper.standard {
      display: block;
    }
    .suite-thumb-inner-wrapper.mobile {
      display: none;
    }

    img {
      position: absolute;
    }
  }

  &.small-grid {
    .thumb {
      margin: 1.5rem;
      img {
        height: 10rem;
      }
    }
  }
  &.medium-grid {
    .thumb {
      // margin: 3rem 2rem;
      // margin: 2.5rem 2rem;
      margin: 0 2rem 5rem 2rem;
      img {
        // height: 20rem;
        height: 16rem;
      }
    }
  }
  &.large-grid {
    .thumb {
      margin: 1.5rem;
      img {
        height: 30rem;
      }
    }
  }
}

body.grid {
  .lightbox { 
    .lightbox-inner-wrapper {
      position: static;
      .image-caption {
        bottom: 3.5rem;
      }
    }
  }
}

body.debug {
  .thumb {
    background-color: pink;
  }
}

/* Mobile */
@media only screen and (max-width : 812px) {
  .grid-wrapper {
    margin: 0 0 0 0;
    padding: 12.5vh 0 12.5vh;
    justify-content: center;

    .thumb {
      margin: 0.675rem 0.625rem;
      
      img {
        // max-height: 6rem;
        max-width: auto;
        vertical-align: middle;
      }
    }
    &.medium-grid {
      .thumb {
        margin: 0 1rem 3rem 1rem;
        img {
          height: 8rem;
        }
      }
    }
    
    .thumb.suite-thumb {
      display: inline-block;
      vertical-align: bottom;
      width: 12rem;
      height: 6rem;
      
      .suite-thumb-inner-wrapper.mobile {
        display: block;
      }
      .suite-thumb-inner-wrapper.standard {
        display: none;
      }

      img {
        position: absolute;
      }
    }
  }
}

/* Mobile horizontal */
@media only screen and (max-height: 767px) {
  body.grid.horizontal {
    .lightbox { 
      .lightbox-inner-wrapper {
        .image-caption {
          display: none;
        }
      }
    }
  }
}
