// @media only screen {
//   .private-project {
//     width: 100%;
//     white-space: nowrap;
//     padding: 1rem;
//     font-size: 0;
//   }
  
//   .image-wrapper, .text-block-wrapper {
//     display: block;
//     padding-bottom: 1rem;
//   }
// }

@media only screen and (max-width: 812px) {
  body.horizontal {
    .private-project {
      // padding: 48vh 1rem 33vh 1rem;
      padding: 0 1rem;
    }
  }
  
  .hide-for-mobile {
    display: none;
  }
  .img-caption {
    display: none;
  }
}

@media only screen and (min-width: 813px) {
  body.private {
    overflow-x: scroll; 
    overflow-y:hidden;
  }
  
  // #project-container {
  .private-project {
    height: 100%;
    width: auto;
    white-space: nowrap;
    padding: 0 0 0 0;
    overflow-y: hidden;
   
    .image-wrapper {
      position: relative;
    }

    .image-wrapper:last-child, .text-block-wrapper:last-child {
      margin-right: 14rem;
    }
  }

  div.bumper {
    display: inline-block;
    width: 30rem;
    height:100%;
    opacity:0;
  }
  
  .image-wrapper, .text-block-wrapper {
    display: inline-block;
    vertical-align: top;
    .img-caption {
      margin-top: 1.5rem;
      // position: absolute;
      // bottom: 1rem;
    }
    .img-caption, .text-inner {
      font-size: 1rem;
      white-space: normal;
    }
  }
}

/*
 * End Shea
 *
 */


/*
 * Medium and up
 *
 */

@media only screen and (min-width : 813px) {
  body.work {
    height: 100%;
    overflow-y: hidden;
  }
}



/*
 * Small screens
 *
 */

@media only screen and (max-width : 812px) {
  body.projects.show {
    height: auto;
    width: 100%;

    .private-project {
      .image-wrapper {
        margin: 0 0 3rem 0;
      }

      .text-block-wrapper {
        margin: 2rem 0 2rem 0;
      }

      .text-block-wrapper.title-block {
        margin: 0 0 2rem 0;
        
        .text-inner {
          .project-client { 
            h2 {
              margin-bottom: 0.125rem;
            }
          }
        }
      }
    }
  }
  .img-caption, .text-inner {
    font-size: 1rem;
    white-space: normal;
  }
  

}

// Horizontal text blocks
@media only screen and (max-width : 900px) {
  body.horizontal {
    .private-project {
      padding: 12.5vh 1rem 0 1rem;

      .image-wrapper, .text-block-wrapper {
        display: block;
        padding-bottom: 1rem;
      }
    }
  }
}
